<template>
  <div>
    <AppLoader v-if="isLoading" class="text-white"></AppLoader>
    <div v-if="!isLoading">
      <div class="relative px-4 md:mt-12 xl:mt-16">
        <h1 class="text-center font-ubuntu pb-12 ">
          <span class="block text-white font-light text-shadow text-3xl sm:text-4xl lg:text-5xl">The PhotoSapien is a </span>
          <span class="block text-white font-medium text-4xl sm:text-6xl lg:text-7xl text-shadow">New Species Of Humans</span>
          <span class="block text-white font-light text-shadow text-3xl sm:text-4xl lg:text-5xl">with an identity on the blockchain</span>
        </h1>
        <div class="px-4">
          <div class="flex items-center justify-center transparent-block text-center rounded-md px-6 sm:px-8 pt-5 pb-5">
            <div class="w-full">
              <div class="text-white text-lg md:text-xl lg:text-2xl pt-0 pb-4">
                Join the wait list
              </div>
              <div class="sm:mx-auto sm:max-w-2xl" v-if="!memberState.hasEmail">
                <div class="sm:flex mb-3">
                  <div class="min-w-0 flex-1">
                    <input type="email" class="custom-input h-full" v-model="emailValue" placeholder="Enter your email">
                  </div>
                  <div class="mt-4 sm:mt-0 sm:ml-3">
                    <button @click="submitEmail" class="btn-content" v-if="!checkingValidation">
                      Join Wait list
                    </button>
                    <button class="btn-content" v-if="checkingValidation">
                      <div class="flex items-center justify-center">
                        <span class="ml-7 mr-2">Join Wait list</span>
                        <AppLoader size-class="w-5 h-5"></AppLoader>
                      </div>
                    </button>
                  </div>
                </div>
                <div class="text-left pt-3">
                  <div class="relative flex items-center h-8">
                    <div class="flex items-center h-5">
                      <input id="marketing" aria-describedby="comments-description" name="comments" v-model="marketingValue" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="marketing" class="font-medium text-white text-base">I agree to receive marketing emails</label>
                    </div>
                  </div>
                  <div class="relative flex items-center">
                    <div class="flex items-center h-8">
                      <input id="minting" aria-describedby="comments-description" name="comments" disabled checked type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="minting" class="font-medium text-white text-base">I want to be notified of the minting</label>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="memberState.hasEmail && !memberState.hasConfirmedEmail">
                <div>
                  <div class="flex flex-col items-center">
                    <div class="mb-4">
                      <h3 class="text-white w-full">We have email a verification code to
                        <strong class="font-ubuntu text-primary-300">{{ memberState.setEmail }}</strong>
                      </h3>
                      <h3 class="text-white">Paste the code in below:</h3>
                    </div>
                    <div class="mb-4">
                      <AppInputCode @final-code="submitFinalCode" :reset="clearNumbers"></AppInputCode>
                    </div>
                    <div class="flex justify-end w-full">
                      <button @click="resetEmail" class="text-primary-300 float-right">Change email</button>
                    </div>
                    <div class="mb-4 text-white" v-if="checkingValidation">
                      <AppLoader>Validating</AppLoader>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { inject, onMounted, ref } from "vue";
import AppInputCode from "@/components/AppInputCode";
import AppLoader from "@/components/AppLoader";
import wait from "@/utils/wait";
import WaitList from "@/services/api/WaitList";

const emitter = require('tiny-emitter/instance');


export default {
  components: { AppLoader, AppInputCode },
  props: {},
  setup()
  {
    const memberState = inject('memberState')

    const updateState = inject('updateState')

    const emailValue = ref('')
    const marketingValue = ref(false)
    const isLoading = ref(true)

    const checkingValidation = ref(false)
    const clearNumbers = ref(false)

    const submitEmail = async () => {
      //Validate email
      checkingValidation.value = true
      let validEmail = validateEmail(emailValue.value)
      if (validEmail) {
        let result = await WaitList.postEmail(memberState.accessToken, emailValue.value, marketingValue.value, true)
        if (!result.success) {
          emitter.emit('fire-alert', result.meta, 'error')
        }
        await updateState();
      } else {
        emitter.emit('fire-alert', 'Please enter a valid email', 'error')
      }

      checkingValidation.value = false
    }

    const resetEmail = () => {
      memberState.hasEmail = false;
    }

    const validateEmail = (email) => {
      return String(email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };


    const submitFinalCode = async (code) => {
      checkingValidation.value = true
      let codeVal = await WaitList.postValidateCode(memberState.accessToken, code)
      checkingValidation.value = false
      if (codeVal.data.status === 'ok') {
        //get status
        emitter.emit('fire-alert', 'You have been validated', 'success')
        await updateState();
        return
      }
      emitter.emit('fire-alert', 'The code did not validate', 'error')
      clearNumbers.value = true
      await wait(200)
      clearNumbers.value = false
    }

    onMounted(async () => {
      emailValue.value = memberState.setEmail
      isLoading.value = false
    })

    return {
      memberState,
      checkingValidation,
      submitFinalCode,
      clearNumbers,
      emailValue,
      marketingValue,
      submitEmail,
      resetEmail,
      isLoading
    }
  }
}
</script>