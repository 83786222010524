<template>
  <div class="flex gap-2" @paste="onPaste" @paste.prevent>
    <input type="text" maxlength="1" ref="code1" @focus="$event.target.select()" v-model="values.number1" @keyup="focusOn('code2')" autocomplete="off" class="app__code">
    <input type="text" maxlength="1" ref="code2" @focus="$event.target.select()" v-model="values.number2" @keyup="focusOn('code3')" autocomplete="off" class="app__code">
    <input type="text" maxlength="1" ref="code3" @focus="$event.target.select()" v-model="values.number3" @keyup="focusOn('code4')" autocomplete="off" class="app__code">
    <input type="text" maxlength="1" ref="code4" @focus="$event.target.select()" v-model="values.number4" @keyup="focusOn('code5')" autocomplete="off" class="app__code">
    <input type="text" maxlength="1" ref="code5" @focus="$event.target.select()" v-model="values.number5" @keyup="focusOn('code6')" autocomplete="off" class="app__code">
    <input type="text" maxlength="1" ref="code6" @focus="$event.target.select()" v-model="values.number6" @keyup="focusOn('code0')" autocomplete="off" class="app__code">
  </div>
</template>
<script>
import { computed, onUpdated, reactive, ref, watch } from "vue";

export default {
  props: {
    reset: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit })
  {

    const code1 = ref(null)
    const code2 = ref(null)
    const code3 = ref(null)
    const code4 = ref(null)
    const code5 = ref(null)
    const code6 = ref(null)

    const values = reactive({
      number1: '',
      number2: '',
      number3: '',
      number4: '',
      number5: '',
      number6: '',
    })


    const clearValues = () => {
      values.number1 = ''
      values.number2 = ''
      values.number3 = ''
      values.number4 = ''
      values.number5 = ''
      values.number6 = ''
    }

    const finalValue = computed(() => {
      return values.number1 + values.number2 + values.number3 + values.number4 + values.number5 + values.number6
    })

    const onPaste = (event) => {
      let pastedData = event.clipboardData.getData('text');
      let chars = pastedData.split('');
      values.number1 = chars[0] ? chars[0] : ''
      values.number2 = chars[1] ? chars[1] : ''
      values.number3 = chars[2] ? chars[2] : ''
      values.number4 = chars[3] ? chars[3] : ''
      values.number5 = chars[4] ? chars[4] : ''
      values.number6 = chars[5] ? chars[5] : ''
      code6.value.blur()
    }

    watch(finalValue, (newVal) => {
      if (newVal.length === 6) {
        emit('final-code', newVal)
      }
    })

    const focusOn = (key) => {
      if (key === 'code0') {
        code6.value.blur()
      } else {
        let codeObj = { code1, code2, code3, code4, code5, code6 }
        codeObj[key].value.focus()
      }
    }

    onUpdated(() => {
      if (props.reset) {
        clearValues()
      }
    })


    return { onPaste, code1, code2, code3, code4, code5, code6, focusOn, values }
  },


}
</script>